html,
body{
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
}

.cursor:hover, .link:hover {
  cursor: pointer;
}

.link {
  padding:5px;
}

li {
  font-family: 'Urbanist';
  line-height: 1.5;
  font-size: 1rem;
}

:focus-visible {
  outline: 0
}

.infos, .infos:visited, .infos:active {
  color: black;
}